import {Component} from '@angular/core';
import {TranslationService} from "./services/translation.service";
import { locale as enLang } from '../assets/i18n/en';
import { locale as seLang } from '../assets/i18n/se';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
    private translationService: TranslationService,
  ) {
    this.translationService.loadTranslations(enLang, seLang);
  }
}
