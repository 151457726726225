export const locale = {
  lang: "en",
  data: {
    TITLE: "POWER ONLINE",
    STEPS: {
      SELECT_PROVIDER: "Select operator",
      SELECT_CONTRACT: "Select plan",
      SELECT_NUMBER: "Select number",
      ORDER: "Authenticate & Sign",
      CONFIRMATION: "The end"
    },
    SELECT_PROVIDER: {
      TITLE: "Select operator"
    },
    SELECT_CONTRACT: {
      TITLE: "Select subscription",
      CONTRACT_TYPES: {
        NEW: "New subscription",
        EXTENSION: "Extend an existing subscription"
      },
      CONTRACT_LENGTH: {
        YEARX1: "No fixation",
        YEARX2: "24 months binding period",
        PLACEHOLDER: "Choose month",
        LABEL: "Binding time"
      },
      CONTRACT_LIST: {
        PLACEHOLDER: "Select subscription",
        LABEL: "Subscription"
      },
      PRICING_CART: {
        PER_MONTH: "per month incl. VAT"
      }
    },
    SELECT_NUMBER: {
      TITLE: "NUMBER SELECTION",
      NUMBER_TYPES: {
        NEW: "New phone number",
        KEEP: "Keep phone number"
      },
      NUMBER_INPUT: {
        PLACEHOLDER: "07X XXX XX XX",
        LABEL: "Current phone number"
      },
      STARTDATE_INPUT: {
        LABEL: "Subscription start date"
      },
      SELF_MOVE_TEXT: "When changing operators, your current phone number will be moved to the operator you have chosen. Your subscription with your current operator will automatically end. The number transfer can take up to a week. If you have a binding period and/or notice period left with your current operator, you may receive a final invoice."
    },
    ORDER: {
      STEP1: {
        TITLE: "1. AUTHENTICATION",
        BANKID: {
          TIMEOUT_TEXT: "The QR code is valid for 1 minute, press the button to load a new QR code",
          RESTART: "Load new QR code"
        },
        SOCIAL_NUM_INPUT: {
          PLACEHOLDER: "YYYYMMDDXXXX",
          LABEL: "Social Security Number"
        },
        EMAIL_INPUT: {
          PLACEHOLDER: "example@example.com",
          LABEL: "Email"
        },
        PHONE_NUM_INPUT: {
          PLACEHOLDER: "07XXXXXXXX",
          LABEL: "Phone number"
        },
        CREATE_ORDER: "Create order"
      },
      STEP2: {
        TITLE: "2. SIGNATURE",
        SIGNATURE_TEXT: "I accept the operator's terms and conditions and the agreement and understand that this purchase entails a payment responsibility and that a credit check will be carried out. When signing a subscription with us, you will need to sign the agreement via BankID."
      },
      BANKID_CONTAINER: {
        TEXT: "Scan the QR code with your BankID app to authenticate",
        ERROR: "Something went wrong",
        TIMEOUT: "It took too long",
        BUTTON: "Open BankID"
      }
    },
    SUMMARY: {
      TITLE: "SUBSCRIPTION SIGNING",
      TEXT: {
        PT1: "By clicking 'Add to cart' below, you only register for the signing of the selected subscription. The registration of the subscription will take place with the selected operator and a credit check will be conducted. The purchase may be canceled if the credit check fails",
        PT2: "You need to complete the purchase of the phone in the next step."
      }
    },
    ORDER_DETAILS: {
      ENTRY_FEE: "Entry fee",
      MONTHS: "Month",
      MONTH: "months",
      MONTH_SHORT: "mo",
      MIN_TOTALCOST: "Minimum total cost",
      TOTAL: "TOTAL",
      TOTAL_DISCOUNT: 'Total discount',
      TO_PAY_DEVICE: "To pay now",
      TO_PAY: "To pay",
    },
    FOOTER: {
      NEXT: "Proceed",
      LAST: "Add to cart",
      PREV: "Back"
    }
  }
};
