import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {InterceptService} from "./interceptors/intercept.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateModule} from "@ngx-translate/core";
// import {FlowModule} from "./pages/flow/flow.module";
// import {ToasterComponent} from "./pages/flow/components/shared/toaster/toaster.component";
// import {ToastComponent} from "./pages/flow/components/shared/toaster/toast/toast.component";
// import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    AppComponent,
    // ToasterComponent,
    // ToastComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    // FlowModule,
    // MatIconModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    // DictionaryService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
