export const locale = {
  lang: 'se',
  data: {
    TITLE: 'POWER ONLINE',
    STEPS: {
      SELECT_PROVIDER: 'Välj operatör',
      SELECT_CONTRACT: 'Välj abonnemang',
      SELECT_NUMBER: 'Nummerval',
      ORDER: 'Authenticate & Sign',
      CONFIRMATION: 'Slutet'
    },
    SELECT_PROVIDER: {
      TITLE: 'Välj operatör',
    },
    SELECT_CONTRACT: {
      TITLE: 'Välj abonnemang',
      CONTRACT_TYPES: {
        NEW: 'Nytt abonnemang',
        EXTENSION: 'Förläng ett nuvarande abonnemang',
      },
      CONTRACT_LENGTH: {
        YEARX1: 'Ingen bindningstid',
        YEARX2: '24 mån bindningstid',
        PLACEHOLDER: 'Välj mån',
        LABEL: 'Bindningstid',
      },
      CONTRACT_LIST: {
        PLACEHOLDER: 'Välj abonnemang',
        LABEL: 'Abonnemang',
      },
      PRICING_CART: {
        PER_MONTH: 'mån inkl. moms',
      }
    },
    SELECT_NUMBER: {
      TITLE: 'NUMMERVAL',
      NUMBER_TYPES: {
        NEW: 'Nytt telefonnummer',
        KEEP: 'Behåll telefonnummer',
      },
      NUMBER_INPUT: {
        PLACEHOLDER:'07X XXX XX XX',
        LABEL:'Nuvarande telefonnummer',
      },
      STARTDATE_INPUT: {
        LABEL:'Startdatum för abonnemang',
      },
      SELF_MOVE_TEXT: 'Vid byte av operatör flyttas ditt nuvarande telefonnummer till den operatör du valt. Ditt abonnemang hos din nuvarande operatör avslutas automatiskt. Det kan ta upp till en vecka innan nummerflytten sker. Har du bindningstid och/eller uppsägningstid kvar hos din nuvarande operatör kan du få en slutfaktura.',
    },
    ORDER: {
      STEP1: {
        TITLE: '1. AUTHENTICATION',
        BANKID: {
          TIMEOUT_TEXT: "QR-koden gäller i 1 minut, tryck på knappen för att ladda en ny QR-kod",
          RESTART: "Ladda ny QR-kod"
        },
        SOCIAL_NUM_INPUT: {
          PLACEHOLDER: 'ÅÅÅÅMMDDXXXX',
          LABEL: 'Personnummer',
        },
        EMAIL_INPUT: {
          PLACEHOLDER: 'exempel@exempel.se',
          LABEL: 'E-post',
        },
        PHONE_NUM_INPUT: {
          PLACEHOLDER: '07XXXXXXXX',
          LABEL: 'Telefonnummer',
        },
        CREATE_ORDER: 'Skapa beställning',
      },
      STEP2: {
        TITLE: '2. SIGNERA',
        SIGNATURE_TEXT: 'Jag godkänner operatörens villkor och avtalet samt är införstådd med att detta köp medför ett betalningsansvar och att en kreditkontroll kommer genomföras. Vid tecknande av abonnemang hos oss kommer du behöva signera avtalet via BankID.',
      },
      BANKID_CONTAINER: {
        TEXT: 'Skanna QR-koden med din BankID-app för att legitimera dig',
        ERROR: 'Något gick fel',
        TIMEOUT: 'Det tog för lång tid',
        BUTTON: 'Öppna BankID',
      },
    },
    SUMMARY: {
      TITLE: 'TECKNING AV ABONNEMANG',
      TEXT: {
        PT1: 'Genom att klicka på ’Lägg i varukorgen’ nedan registrerar du endast teckning av valt abonnemang. Registrtrering av abonnemanget kommer ske hos vald operatör och en kreditupplysning kommer att göras. Köpet kan komma att annuleras om kreditkontrollen inte går igenom',
        PT2: 'Du behöver slutföra köpet av telefonen i nästa steg.',
      },
    },
    ORDER_DETAILS: {
      ENTRY_FEE: 'Startavgift',
      MONTHS: 'Månad',
      MONTH: 'månader',
      MONTH_SHORT: 'mån',
      MIN_TOTALCOST: 'Minsta totalkostnad',
      TOTAL: 'TOTALT',
      TOTAL_DISCOUNT: 'Total rabatt',
      TO_PAY_DEVICE: "Att betala nu",
      TO_PAY: "Att betala",
    },
    FOOTER: {
      NEXT: 'Gå vidare',
      LAST: 'Lägg till i varukorgen',
      PREV: 'Tillbaka',
    },
  }
};
