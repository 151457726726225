import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import * as cr from "crypto-js";

@Injectable()
export class InterceptService implements HttpInterceptor {
  private signature: string | undefined;
  private sharedSecret = 'Yy3p3SwmebSWBqXq';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.signature) {
      this.signature = this.generateSignature(new Date().toString())
    }
    req = req.clone({
      setHeaders: {
        'client-application': `Power`,
        'x-signature': this.signature
      },
    });
    return next.handle(req);
  }

  private generateSignature(input: string): string {
    return cr.AES.encrypt(input, this.sharedSecret).toString();
  }
}
