import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../../environments/environment";

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private langIds: any = [];
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang(environment.locale || 'en');
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];
    locales.forEach(locale => {
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });
    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang: 'en' | 'se') {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      localStorage.setItem('language', lang);
    }
  }

  getSelectedLanguage(): any {
    return localStorage.getItem('language') || this.translate.getDefaultLang();
  }
}
